.all-hands-menu {
    padding: 0 70px;
    height: 90vh;
    display: flex;
    align-items: flex-end;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    min-height: calc(100vh - 500px);

}

.all-hands-frame-inner {
    margin-top: 40px;
    padding-top: 30px;
    width: 100%;
}

.all-hands-frame {
    padding: 0 40px;
    height: 90vh;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: middle;
    min-height:calc(100vh - 500px);
}
.all-hands-frame h2 {
    color:#ffffff;
    margin-top: 70px;
    font-size: 30px;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 179, 89, 0.8);
}
.all-hands-title{
    color:#ffffff !important;
    margin-top: 70px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-shadow: 1px 1px 2px rgba(0, 179, 89, 0.8) !important;
}
.all-hands-qr {
  border:none;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 179, 89, 0.3);
}

.all-hands-btn {
  margin-bottom: 10px;
  height:50px !important;
  color: rgb(0, 179, 89) !important;
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: unset !important;
  border-radius: 100px !important;
}

.all-hands-btn-lite {
  margin-bottom: 10px;
  height:50px !important;
  color: rgb(0, 179, 89) !important;
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: unset !important;
}
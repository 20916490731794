.event-bitkub_mate {
  text-align: center;
  background-color: #EAC2CC;
  width:100%;
}

.event-bitkub_mate-body {
  background-color: #EAC2CC;
  background-image: url('../../../../../../public/img/event/bitkub_mate/bg_form.gif');
  background-size: cover;
  background-position: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  font-size: calc(10px + 2vmin);
  overflow-y: scroll;
  color: white;
}

.event-bitkub_mate-inner{
  background-repeat: no-repeat;
  min-height: calc(100vh - 110px);
  background-position:center bottom;
  display: flex;
  flex-direction: column;
  background-size: 100%;
  padding:20px;
}

.event-bitkub_mate-block{
  background-color: #FFE2E6;
  padding:10px;
  border-radius: 20px;
}
.event-bitkub_mate-block>h3{
  padding-left: 20px;
  text-indent: 10px;
  background-color: #F42C5C;
  padding:5px;
  color:#ffffff;
  border-radius: 10px;
}

.event-bitkub_mate-footer{
  margin: 20px auto -20px;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position:center;
  background-image: url('../../../../../../public/img/event/bitkub_mate/bg_form_footer.png');
}

.event-bitkub_mate-footer.photo{
  background-image: url('../../../../../../public/img/event/bitkub_mate/bg_form_footer_photo.png');
}

.event-bitkub_mate-title{
  margin: 20px auto;
  width: 100%;
  min-height: 200px;
  background-repeat: no-repeat;
  background-position:center;
  background-image: url('../../../../../../public/img/event/bitkub_mate/title.png');
}
.event-bitkub_mate-desc{
  margin: 10px auto;
  width: 100%;
  min-height: 24px;
  color:#F42C5C;
  font-size: 20px;
  font-weight: 700;
}

.event-bitkub_mate-inner .ant-card-bordered{
  border-radius:20px !important;
}
.event-bitkub_mate-inner .ant-card-bordered h5 span{
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  color: #ffffff !important;
  background: #F42C5C;
  border-radius: 5px;
  width:25px;
  height:25px;
}


.event-bitkub_mate-inner .submit{
  background-color: #F42C5C !important;
  border-radius: 10px !important;
  border:none;
}
.event-bitkub_mate-inner .submit.disabled{
    background-color: #F57997 !important;
}

.view-show {display: block}
.view-hide {display: none}

.event-bitkub_mate-title > small{font-size: 16px;}
.event-bitkub_mate-qr{background-color: #FAE6EE;padding: 10px 10px 10px;border-radius: 20px;}

.rank_avatar{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rank_avatar_percent{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFE2E6;
  padding:20px;
  border-radius: 60px;
  height:80px;
  margin-bottom: -40px;
}
.rank_avatar_percent > .text{
  margin-top: -30px;
  font-size: 20px;
  color: #F42C5C;
  font-weight: 700;
}
.rank_avatar_item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFE2E6;
  padding:5px;
  border-radius: 85px;
}
.rank_avatar_image{
  margin:5px;
}
.rank_avatar .avatar_border{
  border:5px solid #F42C5C;
}

.rank_avatar_detail{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:350px;
  z-index: 1;
  margin-bottom: 50px;
}


.rank_avatar .avatar-info{ 
  padding:10px;
  border-radius: 10px;
  margin:10px;
  margin-top:-30px;
  align-items: center;
  justify-content: center;
  display: block;
  text-align: center;
  font-weight: 700; 
  font-size: 16px;
  width:150px;
  background-color: #F42C5C
}
.rank_avatar .avatar-info div{margin:0 auto;}
.event-bitkub_mate-inner .rank_list{
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  border-radius: 20px;
  padding:10px;
}
.event-bitkub_mate-inner .rank_list h4{
  text-align: left;
  color:#F42C5C !important;
}



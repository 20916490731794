  .well_being {
    background-color: #FFFCF8;
    margin: 0;
  }

  .well_being .chart_emo {
    padding: 0;
  }

  .well_being .chart_emo .title_logo {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .well_being .chart_emo .title_logo img {
    width:300px;
    margin: 0 auto;
  }   

  .well_being .chart_emo .chart_data{
    margin:0 auto;
    border-radius: 100%;
    height: 390px;
    width: 390px;
    display: flex;
    text-align: center;
    background-color: #FFF4DE;
    align-items: center;
    justify-content: center;
  }

  .well_being .chart_footer{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .well_being .chart_footer img{
    width:180px;
    margin: 0 auto;
  }

  .well_being .calendar {
    padding: 0;
  }

    .well_being .calendar .title_logo {
        text-align: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .well_being .calendar .title_logo img {
        width:300px;
        margin: 0 auto;
    }   

  .well_being .calendar > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .well_being #month {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    color:#557ABD;
    border-radius: 6px;
    background-color: #FFECBB;
  }
  
  .well_being table {
    border-collapse: collapse;
    width: 100%;
  }
  
  
  .well_being th, .well_being td {
    text-align: center;
    padding: 2px;
  }
  .well_being td span{
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #C1C1C1;
    font-weight: 700;
    border-radius: 100%;
    font-size: 16px;
    line-height: 40px;
    margin:0 auto;
    padding:0;  
    display: inline-block;
    background-size: 38px;
    background-position: center;
    background-repeat: no-repeat;
  }
  

  .well_being th { 
    color:#557ABD;
  }

  .well_being td.date span{
      color: #557ABD;
      background-image: url('../../../../../../public/img/liff/event/well_being/date.svg');
  }
  .well_being td.today span{
      color: transparent !important; 
      background-image: url('../../../../../../public/img/liff/event/well_being/today.svg');
  }
  .well_being td.future span{
      background-image: url('../../../../../../public/img/liff/event/well_being/date.svg');
  }
  .well_being td.emo span, .chart_emo .emo span{
      color: transparent !important; 
      display: inline-block;
  }
  .well_being td.emo.angry span, .select_emo td .emo.angry, .chart_emo .emo.angry{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Angry.svg?a=1');
  }
  .well_being td.emo.anxiety span, .select_emo td .emo.anxiety, .chart_emo .emo.anxiety{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Anxiety.svg?a=1');
  }
  .well_being td.emo.calm span, .select_emo td .emo.calm, .chart_emo .emo.calm{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Calm.svg?a=1');
  }
  .well_being td.emo.disappointed span, .select_emo td .emo.disappointed, .chart_emo .emo.disappointed{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Disappointed.svg?a=1');
  }
  .well_being td.emo.happy span, .select_emo td .emo.happy, .chart_emo .emo.happy{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Happy.svg?a=1');
  }
  .well_being td.emo.loved span, .select_emo td .emo.loved, .chart_emo .emo.loved{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Loved.svg?a=1');
  }
  .well_being td.emo.proud span, .select_emo td .emo.proud, .chart_emo .emo.proud{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Proud.svg?a=1');
  }
  .well_being td.emo.sad span, .select_emo td .emo.sad, .chart_emo .emo.sad{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Sad.svg?a=1');
  }
  .well_being td.emo.scared span, .select_emo td .emo.scared, .chart_emo .emo.scared{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Scared.svg?a=1');
  }
  .well_being td.emo.surprised span, .select_emo td .emo.surprised, .chart_emo .emo.surprised{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Surprised.svg?a=1');
  }


  .well_being td.emo.grateful span, .select_emo td .emo.grateful, .chart_emo .emo.grateful{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Grateful.svg?a=1');
  }
  .well_being td.emo.motivated span, .select_emo td .emo.motivated, .chart_emo .emo.motivated{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Motivated.svg?a=1');
  }
  .well_being td.emo.confident span, .select_emo td .emo.confident, .chart_emo .emo.confident{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Confident.svg?a=1');
  }
  .well_being td.emo.hopeful span, .select_emo td .emo.hopeful, .chart_emo .emo.hopeful{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Hopeful.svg?a=1');
  }
  .well_being td.emo.energetic span, .select_emo td .emo.energetic, .chart_emo .emo.energetic{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Energetic.svg?a=1');
  }
  .well_being td.emo.tired span, .select_emo td .emo.tired, .chart_emo .emo.tired{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Tired.svg?a=1');
  }
  .well_being td.emo.bored span, .select_emo td .emo.bored, .chart_emo .emo.bored{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Bored.svg?a=1');
  }
  .well_being td.emo.lonely span, .select_emo td .emo.lonely, .chart_emo .emo.lonely{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Lonely.svg?a=1');
  }
  .well_being td.emo.curious span, .select_emo td .emo.curious, .chart_emo .emo.curious{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Curious.svg?a=1');
  }
  .well_being td.emo.frustrated span, .select_emo td .emo.frustrated, .chart_emo .emo.frustrated{
    background-image: url('../../../../../../public/img/liff/event/well_being/emo/Frustrated.svg?a=1');
  }


  .well_being td:hover {
    background-color: #eee;
    cursor: pointer;
  }
  
  .well_being .calendar_footer{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  .well_being .calendar_footer img{
    width:250px;
    margin: 0 auto;
  }
  .modal_felling .ant-modal-content, .modal_felling .ant-modal-header{
    background-color: #FFF2E1;
  }
  .modal_felling .ant-btn-primary{
    background-color: #557ABD !important;
    border-color: #557ABD !important;
  }
  
  .select_emo{
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
  }
  .select_emo tr{
    vertical-align: baseline;
  }
  .select_emo td {
    text-align: center;
    padding: 2px;
  }
  .select_emo td div{
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
    font-size: 16px;
    line-height: 40px;
    margin:0 auto;
    padding:0;  
    background-size: 38px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .felling_block {
    text-align: center;
    vertical-align: middle;
  }

  .felling_chart{
    width:300px;
    height:300px;
    border-radius: 100%;
    background-color: #FFF2E1;
  }
  .felling_chart svg {
    display: inline-block;
    transition: width 0.3s ease, height 0.3s ease;
  }

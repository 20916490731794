:fullscreen .toolbar {
  position: fixed;
  top: 0;
}
.tv-main {
  text-align: center;
}
.tv-main-body {
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #025637;
}
.badge_company_name{width:300px;position: absolute;top: 0;right: 0}
.badge_ribbon{width:336px;position: absolute;top: 0;left: 0}
.badge_line{width:183px;position: absolute;bottom: 0;right: 0}
.body_init {
  background-image: url('../../../../public/img/event/main/bg_logo.gif');
}
.body_prompt {
  background-image: url('../../../../public/img/event/main/bg.gif');
}
.body_running {
  background-image: url('../../../../public/img/event/main/bg.gif');
}
.body_end {
  background-image: url('../../../../public/img/event/main/bg.gif');
}



.match_card {
  width: 300px;
  border: 0px;
  display: inline-block;
  position: relative;
  z-index: 1;
  border: 0px solid #000000;
  background-color: transparent;
  font-family: Futura, Tahoma, sans-serif;
  color: #ffffff;
  margin-left: 20px;
}
.match_card::after {
  content: "'";
  color: transparent;
  position: absolute;
  width: 100%;
  height: 80px;
  left: 0px;
  top: 0px;
  z-index: -1;
  border-radius: 25px;
  background-color: #ffffff;
  transform: skew(-10deg);
-webkit-transform: skew(-20deg);
-o-transform: skew(-20deg);
-moz-transform: skew(-20deg);
-ms-transform: skew(-20deg);
}

.match_btn{
  padding:10px 50px;
  font-size:30px;
  height:auto;
  color:#ffffff
}

.tv-main-block_center {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #025637;
}

.tv-main-title{
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
}
.tv-main-frame{
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
}
.tv-main-title > small{font-size: 16px;}
.tv-main-qr{background-color: #ffffff;padding: 10px 10px 10px;border-radius: 20px;}

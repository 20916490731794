.tv-checkin {
  text-align: center;
}
.tv-checkin-body {
  background-color: #ffffff;
  background-image: url('../../../../public/img/event/checkin/bg.gif');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #025637;
}
.tv-checkin-title{
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
}
.tv-checkin-title > small{font-size: 16px;}
.tv-checkin-qr{background-color: #ffffff;padding: 10px 10px 10px;border-radius: 20px;}

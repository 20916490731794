
.bitkub-summit-frame {
  padding: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  min-height:calc(100vh - 500px);
}
.bitkub-summit-frame h2 {
    font-size: 20px;
    font-weight: 700;
}
.bitkub-summit-qr {
  padding:0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

a.bitkub-summit-btn {
  background-color: rgba(255, 255, 255, 0.93);
  border-radius: 10px;
  padding:10px 10px 3px;
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
      width: 100%;
}
a.bitkub-summit-btn img{
  margin:auto;
  height:40px
}

a.bitkub-summit-btn img.checkin{
  display: block
}

a.bitkub-summit-btn:hover img.checkin,a.bitkub-summit-btn.active img.checkin{
  display: none
}

a.bitkub-summit-btn img.checkin-hover{
  display: none
}

a.bitkub-summit-btn:hover img.checkin-hover,a.bitkub-summit-btn.active img.checkin-hover{
  display: block
}


a.bitkub-summit-btn img.checkout{
  display: block
}

a.bitkub-summit-btn:hover img.checkout,a.bitkub-summit-btn.active img.checkout{
  display: none
}

a.bitkub-summit-btn img.checkout-hover{
  display: none
}

a.bitkub-summit-btn:hover img.checkout-hover,a.bitkub-summit-btn.active img.checkout-hover{
  display: block
}



a.bitkub-summit-btn:hover {
  background-color: rgba(39, 39, 39, 0.93);
}

a.bitkub-summit-btn.active{
  background-color: rgba(39, 39, 39, 0.93);
}
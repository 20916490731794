.card-aganda{
	border-radius: 12px;
	padding: 2px;
	background-color: rgba(0, 0, 0, 0.2);
	color: white;
	font-size:12px;
}

.card-aganda .ant-card-body{
	padding:2px !important;
}
.event-title {
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 12px;
    color: white;
    font-size: 30px;
    text-align: center;
}
.event-label {
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
}
.container {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.card-container {
  position: relative;
  width: 150px;
  height: 220px;
  cursor: pointer; /* Ensure it's clear this is interactive */
}

.card {
	position: absolute;
	width: 150px;
	height: 240px;
	border-radius: 15px;
	background-color: linear-gradient(to bottom, #66bb6a, #388e3c);
	padding:0;  
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../../../../../public/img/liff/event/outing_2024/card_active_hover.svg');
	transition: transform 0.5s ease-in-out; /* Smooth transformation */
}

.card-block {
	width: 150px;
	height: 240px;
	border-radius: 15px;
	background-color: linear-gradient(to bottom, #66bb6a, #388e3c);
	padding:0;  
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../../../../../public/img/liff/event/outing_2024/card_active_hover.svg');
	transition: transform 0.5s ease-in-out; /* Smooth transformation */
}
.card-block-done {
  width: 150px;
  height: 240px;
  border-radius: 15px;
  background-color: linear-gradient(to bottom, #66bb6a, #388e3c);
  padding:0;  
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../../../../public/img/liff/event/outing_2024/card_active.svg');
  transition: transform 0.5s ease-in-out; /* Smooth transformation */
}

/* Initial positions for the stacked cards */
.card1 {
  z-index: 3;
  transform: translateY(0px) rotate(0deg);
}

.card2 {
  z-index: 2;
  transform: translateY(10px) rotate(-10deg);
}

.card3 {
  z-index: 1;
  transform: translateY(20px) rotate(10deg);
}

/* Hover effect to align all cards */
.card-container:hover .card1 {
  transform: translateY(-10px) rotate(0deg);
}

.card-container:hover .card2 {
  transform: translateY(0px) rotate(0deg);
}

.card-container:hover .card3 {
  transform: translateY(10px) rotate(0deg);
}
.card.pop {
  animation: popSlideFade 0.8s ease-in-out forwards;
}

@keyframes popSlideFade {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) translateY(-20px);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.8) translateY(-100px);
    opacity: 0;
  }
}

.card-leader {
  color: #ffffff;
}
.card-leader .ant-divider-vertical {
  height: 1.7em;
}
.card-leader:not(.other) .ant-divider-vertical {
  border-color: #ffffff78;
}
.card-leader h4 {
  margin: 0;
  background-color: #0000001c;
  line-height: 80px;
  width: 32px;
  border-radius: 3px;
  text-align: center;
}
.card-leader:not(.other) h4 {
  color: #ffffff !important;
}
.card-leader strong {
  font-size: 1.1rem;
}

.card-leader.other small {
  color: #878a99 !important;
}

.card-leader:not(.other) strong {
  color: #ffffff !important;
}

.tv-bitkub_mate {
  text-align: center;
}
.tv-bitkub_mate-body {
  background-color: #ffffff;
  background-image: url('../../../../public/img/event/bitkub_mate/register.gif');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tv-bitkub_mate-title{
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
}
.tv-bitkub_mate-title > small{font-size: 16px;}
.tv-bitkub_mate-qr{background-color: #ffffff;padding: 20px;border-radius: 20px;position: absolute;top: 680px;}